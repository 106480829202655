
import blocksToHtml from '@sanity/block-content-to-html';
import { getFile } from '@sanity/asset-utils';
import config from '../../starter.config';

export default {
  props: {
    text: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      blocks: {},
      legendBlocks: {}
    };
  },
  computed: {
    block1() {
      let blockContent;
      try {
        const link = (props) => {
          const isInternalLink = !!props.mark.intLink;
          const isExternalLink = !!props.mark.extLink;
          const isJumplink = !!props.mark.isJumplink;
          const isEmail = !!props.mark.eMail;
          const isPhoneNumber = !!props.mark.tel;
          const target = props.mark.target ? '_blank' : '_self';

          if (isExternalLink) {
            return blocksToHtml.h(
              'a',
              {
                href: props.mark.extLink,
                target
              },
              props.children
            );
          }

          if (isInternalLink) {
            const _ref = props.mark.intLink._ref;
            const page = this.getPageByRef(_ref);
            const locale =
              this.$i18n.locale === config.i18n.defaultLocale ? '' : `${this.$i18n.locale}`;
            const pageSlug = page.url === null || page.url === undefined ? '' : page.url.fullPath;
            const href = `/${locale}/${pageSlug}#${props.mark.sectionId}`;

            if (isJumplink) {
              return blocksToHtml.h(
                'a',
                {
                  href,
                  target: '_self'
                },
                props.children
              );
            }

            return blocksToHtml.h(
              'a',
              {
                href: `/${page.url.fullPath}`,
                target: '_self'
              },
              props.children
            );
          }

          if (isEmail) {
            return blocksToHtml.h(
              'a',
              {
                href: `mailto:${props.mark.eMail}`,
                target: '_self'
              },
              props.children
            );
          }

          if (isPhoneNumber) {
            return blocksToHtml.h(
              'a',
              {
                href: `tel:${props.mark.tel}`,
                target: '_self'
              },
              props.children
            );
          }
        };

        const download = (props) => {
          const fileRef = props.mark.fileRef.asset._ref;
          if (!fileRef) {
            return;
          }

          const file = getFile(fileRef, this.$config.api);
          const fileName = props.mark.fileName;
          const href = fileName
            ? `${file.asset.url}?dl=${fileName}.${file.asset.extension}`
            : `${file.asset.url}`;

          return blocksToHtml.h(
            'a',
            {
              href,
              target: '_blank'
            },
            props.children
          );
        };

        const sub = (props) => {
          return blocksToHtml.h('sub', props.children);
        };

        const sup = (props) => {
          return blocksToHtml.h('sup', props.children);
        };
        if (this.text) {
          // TODO: understand why
          // it converts type 'richtext' to 'block'
          // This is a workaround for Preview Mode.
          blockContent = blocksToHtml({
            blocks: this.text.map((item) => ({
              ...item,
              _type: 'block'
            })),
            serializers: { marks: { link, download, sub, sup } }
          });
        }
      } catch (e) {
        blockContent = '';
        console.warn('Blocks content serializer failed!', e);
      }

      return blockContent;
    }
  },
  mounted() {}
};

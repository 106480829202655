
import { mapGetters } from 'vuex';
// import SVGtransitionBorder from '../../assets/svg/transitionBorder.svg';

export default {
  // components: { SVGtransitionBorder },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      bgPosition: 0,
      img: null,
      lastIndex: 0,
      frameCount: 24,
      context: null,
      canvas: null,
      html: null,
      sectionScroll: null
    };
  },
  computed: {
    ...mapGetters({
      isProductDetailsSticky: 'ui/isProductDetailsSticky'
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScrollAnimation);
  },
  mounted() {
    this.initScrollingAnimation();
  },
  methods: {
    toggleNavOverlay() {
      this.$store.commit('ui/toggleOverlay', !this.isOverlayActive);
    },
    initScrollingAnimation() {
      this.html = document.documentElement;
      this.canvas = document.getElementById('hero-lightpass');
      this.context = this.canvas.getContext('2d');
      this.sectionScroll = document.getElementById('section-scroll');

      this.$nextTick(() => {
        // The timeout is here to avoid a wrong initialization of values
        setTimeout((a) => {
          const obj = this.$refs.endVisibleSection;
          const self = this;
          const observer = new IntersectionObserver(function (entries) {
            if (!entries[0].isIntersecting) {
              self.$store.commit('ui/setisProductDetailsSticky', false);
            } else {
              self.$store.commit('ui/setisProductDetailsSticky', true);
            }
          });

          observer.observe(obj);
        }, 1000);

        // Should fit the image sizes
        this.canvas.width = 2200;
        this.canvas.height = 1400;
        this.img = new Image();
        this.img.src = this.getCurrentFrame(0);
        const self = this;
        this.img.onload = function () {
          self.context.drawImage(self.img, 0, 0);
        };
      });

      window.addEventListener('scroll', this.onScrollAnimation);
    },
    onScrollAnimation() {
      const sectionTop = this.sectionScroll.offsetTop;
      const scrollTop = this.html.scrollTop;
      // We do the *2 because of the invisible section we add
      const scrollFraction =
        (scrollTop - sectionTop) / (this.sectionScroll.clientHeight - window.innerHeight * 2);
      const frameIndex = Math.min(this.frameCount - 1, Math.ceil(scrollFraction * this.frameCount));
      if (
        Math.ceil(scrollFraction * this.frameCount) < this.frameCount &&
        this.lastIndex !== frameIndex
      ) {
        const self = this;
        requestAnimationFrame(() => self.updateImage(frameIndex + 1));
        this.lastIndex = frameIndex;
      }
    },
    updateImage(index) {
      const tempImg = new Image();
      const self = this;
      tempImg.onload = () => {
        if (index >= 0) {
          self.img.src = self.getCurrentFrame(index);
          self.bgPosition = Math.round(index * (99 / self.frameCount));
          self.context.clearRect(0, 0, self.canvas.width, self.canvas.height);
          self.context.drawImage(tempImg, 0, 0);
        }
      };
      if (index >= 0) {
        tempImg.src = self.getCurrentFrame(index);
      }
    },
    getCurrentFrame(index) {
      return require(`../../assets/images/echo-frames2/Dose-Alpha-Cut-25fps_${index
        .toString()
        .padStart(5, '0')}.webp`);
    }
  }
};
